import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import axios from "axios";
import "tw-elements";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// axios.defaults.baseURL = "https://stghribe.karmaalab.com/";
axios.defaults.baseURL = "https://stgpshirebe.karmaalab.com/";

export const User_Fe_url = "http://ps.stghriuser.karmaalab.com/"

export const Team_FE_url ="http://ps.stghriteam.karmaalab.com/"


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();